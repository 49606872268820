import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Portfolio from './Portfolio';
import Blog from './Blog';
import Experience from './Experience';
import Contact from './Contact';
import { animateScroll, scroller } from 'react-scroll';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#093170',
    },
    secondary: {
      main: '#ab003c',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(16, 0, 6),
  },
  paperBackground: {
    backgroundColor: theme.palette.background.paper,
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  card2: {
    height: '100%',
    border: 'none',
    boxShadow: "none",
  },
  cardMedia2: {
    height: 167,
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

export default function Home() {

  const [selectedNav, setButton] = React.useState(false);
  const classes = useStyles();
  const menuList = ['Contact', 'Blog', 'Experience', 'Portfolio'];

  const navClick = (key) => {
    scroller.scrollTo(key, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
    setButton(key);
  };

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  }

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppBar>
          <Container maxWidth="xl">
            <Toolbar disableGutters>

              {/* Big Title */}
              <Typography component="div" variant="h6" className={classes.title} sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}>
                Adibiarso
              </Typography>

              {/* Big Menu */}
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {menuList.map(key =>
                  <Button color={key === selectedNav ? 'secondary' : 'inherit'} variant={key === selectedNav ? 'contained' : 'text'} onClick={() => navClick(key)} key={key}>{key}</Button>
                )}
              </Box>


            </Toolbar>
          </Container>
        </AppBar>
        <main>
          {/* Hero unit */}
          <div id="Contact" className={classes.heroContent}>
            <Contact></Contact>
          </div>

          <div id="Blog">
            <Blog></Blog>
          </div>

          <div id="Experience" className={classes.paperBackground}>
            <Experience></Experience>
          </div>

          <div id="Portfolio">
            <Portfolio></Portfolio>
          </div>

        </main>
        {/* Footer */}
        <footer className={classes.footer}>
          <div id="backtotop">
            <Link href="#">
              <Typography onClick={scrollToTop} variant="subtitle1" align="center" color="textSecondary" component="p">
                Back to top
              </Typography>
            </Link>
          </div>
        </footer>
        {/* End footer */}
      </ThemeProvider>
    </React.Fragment>
  );
}