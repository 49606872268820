import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { Box } from '@material-ui/core';
import Parser from 'rss-parser'

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(16, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  cardporto: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  card2: {
    height: '100%',
    border: 'none',
    boxShadow: "none",
  },
  cardMedia2: {
    height: 167,
  },
  cardContent: {
    flexGrow: 1,
  },
}));

export default function Blog() {
  const classes = useStyles();
  const [feed, setFeed] = useState([]);

  useEffect(() => {
    let parser = new Parser();
    (async () => {

      let feedResponse = await parser.parseURL('https://adibiarso.com/api/blog/feeds/posts/default/-/tech');
      let lastFeed = feedResponse.items.slice(0, 3);
      setFeed(lastFeed);
    })();
  }, []);

  return (
    <Container className={classes.cardGrid} maxWidth="md">
      {/* End hero unit */}
      <Box mb={4}>
        <Typography gutterBottom variant="h4" component="h4" align="center">
          Blog
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {feed.map((card) => (
          <Grid item key={card.id} xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h6" component="h4">
                  {card.title}
                </Typography>
                <Typography>
                  {card.pubDate.substr(0, 4)}
                </Typography>
                <Typography>
                  {card.summary.substr(0, 100)}...
                </Typography>
              </CardContent>
              <CardActions>
                <Link href={card.link} target="_blank">
                  <Button size="small" color="primary">
                    Read More
                  </Button>
                </Link>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box mt={4}>
        <Link href="https://adibiarso.blogspot.com" target="_blank">
          <Typography gutterBottom variant="h6" component="h6" align="center">
            Read All
          </Typography>
        </Link>
      </Box>
    </Container>

  );
}
