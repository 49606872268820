import React from 'react';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import GitHubIcon from '@material-ui/icons/GitHub';
import MailIcon from '@material-ui/icons/Mail';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
  },
  card2: {
    height: '100%',
    border: 'none',
    boxShadow: "none",
  },
  cardMedia2: {
    height: 167,
  },
}));

export default function Contact() {
  const classes = useStyles();

  return (
    <Container maxWidth="sm">
      <Grid container spacing={3}>
        {/* photo */}
        <Grid item xs={4}>
          <Card className={classes.card2}>
            <CardMedia
              className={classes.cardMedia2}
              image={window.location.origin + '/profile.png'}
              title="Image title"
            />
          </Card>
        </Grid>

        {/* rightside */}
        <Grid item xs={8}>
          <Grid item xs={12}>
            <Typography component="h3" variant="h4" align="left" color="textPrimary" gutterBottom>
              Luqman Adibiarso
            </Typography>
            <Typography component="h3" variant="h5" align="left" color="textPrimary" gutterBottom>
              Software Engineer
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Link href="https://www.linkedin.com/in/adibiarso/" target="_blank"><LinkedInIcon className={classes.icon} /></Link>
            <Link href="https://www.github.com/adibiarsotp/" target="_blank"><GitHubIcon className={classes.icon} /></Link>
            <Link href="mailto:aluqman54@gmail.com" target="_blank"><MailIcon className={classes.icon} /></Link>
          </Grid>
        </Grid>
      </Grid>
    </Container>

  );
}
