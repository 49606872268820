import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Box } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(16, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  cardporto: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
  },
  cardMedia: {
    paddingTop: '100%'
  },
  card2: {
    height: '100%',
    border: 'none',
    boxShadow: "none",
  },
  cardMedia2: {
    height: 167,
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  paper: {
    position: 'absolute',
    width: 600,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));


export default function Portfolio() {
  const classes = useStyles();
  //let title = "";

  const [open, setOpen] = React.useState(false);
  const [card, setCard] = React.useState(false);


  const handleOpen = (card) => {
    setCard(card)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let modalbody = (
    <Box className={classes.paper}>
      <CardMedia
        className={classes.cardMedia}
        image={"https://adibiarso.com/img/upload/" + card.gambar}
        title={card.nama}
      />
      <Typography id="modal-modal-title" variant="h6" component="h2">
        {card.nama}
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        {card.date}
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        {card.deskripsi}
      </Typography>
    </Box>
  )

  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [items, setItems] = React.useState([]);

  useEffect(() => {
    fetch("https://adibiarso.com/api/portfolio")
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [])

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <Container className={classes.cardGrid} maxWidth="md">
        {/* End hero unit */}
        <Box mb={4}>
          <Typography gutterBottom variant="h4" component="h4" align="center">
            Portfolio
          </Typography>
        </Box>
        <Grid container spacing={4}>
          {items.map((card) => (
            <Grid item key={card.id} xs={12} sm={6} md={3}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardMedia}
                  image={"https://adibiarso.com/img/upload/" + card.gambar}
                  title={card.nama}
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant="h6" component="h2">
                    {card.nama}
                  </Typography>
                  <Typography>
                    {card.deskripsi.substr(0,40)}...
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" color="primary" onClick={() => handleOpen(card)}>
                    View
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          {modalbody}
        </Modal>
      </Container>

    );
  }
}
